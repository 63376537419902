<template>
  <div class="contractData">
    <el-divider content-position="left">合同数据</el-divider>
    <el-form-item label="违约金：">
      <el-input clearable class="labelwidth" v-model="form.contract_overdue">
        <template slot="prepend">每逾期</template>
        <template slot="append">天</template>
      </el-input>
      <el-input clearable class="labelwidth" v-model="form.contract_overdue_value" style="marginLeft: 20px;">
        <template slot="prepend">按照逾期支付费用的</template>
        <template slot="append">%违约金</template>
      </el-input>
    </el-form-item>
    <el-form-item label="经营范围：">
      <el-input placeholder="请输入经营范围" v-model="form.contract_manage" clearable class="labelwidth"></el-input>
    </el-form-item>
    <el-form-item label="特别约定：">
      <el-input placeholder="请输入特别约定" :rows="3" type="textarea" v-model="form.contract_special" clearable
        class="labelwidth"></el-input>
    </el-form-item>
  </div>
</template>

<script>
import {
  mapActions,
  mapState,
  mapMutations
} from 'vuex';

export default {
  name: 'contractData',
  data () {
    return {};
  },
  computed: {
    ...mapState('contract', ['form', 'rules'])
  }
};
</script>

<style lang="scss" scoped>
.labelwidth {
  width: 400px !important;
}

.public-tips {
  margin-left: 10px;
}
</style>
