<template>
  <div class="templateField" v-if="form.dcList">
    <el-divider content-position="left">固定缴费费用</el-divider>
    <div v-for="(item, index) in form.dcList" :key="index">
      <el-row>
        <el-col :span="5">
          <el-form-item :label="item.contract_fee_name" :prop="`dcList[${index}].pay_price`"
            :rules="{ required: item.required_type == 10 ? true : false, message: `请输入${item.contract_fee_name}`, trigger: 'blur' }">
            <el-input-number :placeholder="`请输入${item.contract_fee_name}`" :controls="false" :min="0"
              v-model="item.pay_price" class="public-input"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="`缴费时间${item.contract_fee_name}`" :required="timeRequired">
            <el-date-picker class="public-input" format="yyyy-MM-dd" :clearable="false" v-model="item.pay_time"
              value-format="yyyy-MM-dd" placeholder="缴费时间"></el-date-picker>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="5" v-if="item.alreadyPayCost && item.pay_price > item.alreadyPayCost">
          <el-form-item :label="`缴费时间${item.contract_fee_name}`" :required="timeRequired">
            <el-date-picker class="public-input" format="yyyy-MM-dd" :clearable="false" v-model="item.pay_time" value-format="yyyy-MM-dd" placeholder="缴费时间"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5" v-if="!item.alreadyPayCost">
          <el-form-item :label="`缴费时间${item.contract_fee_name}`" :required="timeRequired">
            <el-date-picker class="public-input" format="yyyy-MM-dd" v-model="item.pay_time" :clearable="false" value-format="yyyy-MM-dd" placeholder="缴费时间"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5" v-if="item.alreadyPayCost">
          <el-form-item :label="`原合同已缴${item.contract_fee_name}`">
            <span>￥{{ item.alreadyPayCost }}</span>
          </el-form-item>
        </el-col> -->
        <el-col :span="5">
          <el-form-item :label="`已缴${item.contract_fee_name}`">
            <el-input-number placeholder="请输入已缴金额" :controls="false" :min="0" v-model="item.paid_amount"
              class="public-input"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="`实际缴费时间`">
            <el-date-picker class="public-input" format="yyyy-MM-dd" :clearable="false" v-model="item.paid_time"
              value-format="yyyy-MM-dd" placeholder="缴费时间"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapState,
  mapMutations
} from 'vuex';
import methods from "methods";

export default {
  name: 'templateField',
  data () {
    return {
      timeRequired: false
    };
  },
  computed: {
    ...mapState('contract', ['form', 'rules'])
  },
  methods: {
    payPriceChange (item) {
      item.pay_time = this.$common.formatDateTime(new Date());
      if (item.pay_price) {
        this.timeRequired = true
      } else {
        this.timeRequired = false
        item.pay_time = null
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-input-number .el-input__inner {
  text-align: left;
}
</style>
