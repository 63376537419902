<template>
  <div class="paymentAmount" v-if="form.month_price">
    <el-divider content-position="left">缴费金额（此栏金额与商铺租赁数据关联）</el-divider>
    <el-row>
      <el-col :span="5" v-for="(item, index) in form.monthTotal" :key="index">
        <el-form-item :label="`每月总${item.contract_fee_name}`">
          <el-input-number :placeholder="`月总${item.contract_fee_name}`" :controls="false" :min="0"
            v-model="item.month_pay_price" type="number" @change="calculateMonth($event, index)"
            class="public-input"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item label="每月应缴费用合计:">
          <el-input placeholder="每月应缴费用合计" v-model="form.month_price" :disabled="true" class="public-input">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <div>
      <div v-for="(item, index) in form.contract_increasing" :key="index">
        <el-form-item :label="`第${parseInt(index) + 1}年度`">
          <template v-if="index > 0">
            <div v-for="(list, j) in item.fees_list" :key="j" style="margin-bottom: 20px">
              <el-form-item :label="`${list.name}递增率`">
                <el-input placeholder="递增率" style="width: 250px; marginleft: 15px" @change="calculateData"
                  v-model.trim="list.contract_increasing" class="input-with-select">
                  <el-select v-model="list.change" @change="calculateData" style="width: 100px" slot="prepend"
                    placeholder="请选择">
                    <el-option :value="1" label="百分比"></el-option>
                    <el-option :value="2" label="固定金额"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </div>
            <!-- <span>
              递增费用
              <el-select v-model="item.type" multiple @change="calculateData">
                <el-option v-for="cost in form.monthTotal" :key="cost.id" :value="cost.id.toString()"
                  :label="cost.contract_fee_name"></el-option>
              </el-select>
            </span>
            <span>
              <el-input placeholder="递增率" style="width: 250px; marginLeft:15px;" @change="calculateData"
                v-model.trim="item.contract_increasing" class="input-with-select">
                <el-select v-model="item.change" @change="calculateData" style="width: 100px;" slot="prepend"
                  placeholder="请选择">
                  <el-option :value="1" label="百分比"></el-option>
                  <el-option :value="2" label="固定金额"></el-option>
                </el-select>
              </el-input>
            </span> -->
          </template>
          <template v-if="index > 0">
            <div style="margin: 15px 0">
              <span v-for="(fees, j) in item.fees_list" :key="j">
                每月总{{ fees.name }}:
                <el-input class="public-input" v-model="fees.pay_price" disabled></el-input>
              </span>
            </div>
          </template>
          <div style="margin-bottom: 15px" v-if="index > 0">
            <span>每月应缴费用合计:</span>
            <el-input class="public-input" v-model="item.month_pay_price" disabled></el-input>
          </div>
          <div>
            <span>{{ `第${parseInt(index) + 1}年度费用合计` }}：</span>
            <el-input disabled v-model="item.total_pay_price" class="public-input"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="缴费项" type="card">
          <el-tabs v-model="item.active_name">
            <el-tab-pane :label="list.name" :name="k.toString()" v-for="(list, k) in item.fees_list" :key="k">
              <el-form-item label="应缴总金额:">
                {{ list.total }}
              </el-form-item>
              <el-form-item label="缴费周期">
                <el-input placeholder="请输入缴费周期" @change="blurRentInfo(item, list, k)"
                  v-model="item.fees_list[k].contract_rent_total" type="number" class="labelwidth"></el-input>
              </el-form-item>
              <div v-if="list.contract_rentInfo.length > 0">
                <div v-for="(pay, j) in list.contract_rentInfo" :key="j" style="margin-top: 14px">
                  <div style="
                      margin-bottom: 10px;
                      display: flex;
                      align-items: center;
                      flex-wrap: wrap;
                    ">
                    <el-form-item label="开始时间:" style="flex: 1">
                      {{ getListStartTime(index, k, list, j) }}
                    </el-form-item>
                    <el-form-item label="结束时间:" :prop="'contract_increasing.' +
                      index +
                      '.fees_list.' +
                      k +
                      '.contract_rentInfo.' +
                      j +
                      '.pay_end_time'
                      " :rules="{
    required: true,
    message: '缴费时间不能为空',
    trigger: 'change',
  }" style="flex: 1">
                      <el-date-picker v-model="pay.pay_end_time" :disabled="j === list.contract_rentInfo.length - 1"
                        :pickerOptions="{
                          disabledDate(time) {
                            return (
                              time.getTime() < disEndTime(index, k, list, j) ||
                              time.getTime() >
                              new Date(
                                list.contract_rentInfo[
                                  list.contract_rentInfo.length - 1
                                ].pay_end_time
                              ).getTime() -
                              24 * 60 * 60 * 1000
                            );
                          },
                        }" @change="
  listEndChange($event, pay, j, list.contract_rentInfo)
  " class="public-input" :clearable="false" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        placeholder="请选择结束时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="缴费金额:" :prop="'contract_increasing.' +
                      index +
                      '.fees_list.' +
                      k +
                      '.contract_rentInfo.' +
                      j +
                      '.pay_price'
                      " :rules="{
    required: true,
    message: '请输入缴费金额',
    trigger: 'blur',
  }" style="flex: 1">
                      <el-input placeholder="请输入缴费金额" @change="amountPayment($event, pay, list, item)"
                        v-model.trim="pay.pay_price" type="number" class="public-input"></el-input>
                    </el-form-item>
                  </div>
                  <div style="
                      margin-bottom: 10px;
                      display: flex;
                      align-items: center;
                      flex-wrap: wrap;
                    ">
                    <el-form-item label="约定缴费时间:" :prop="'contract_increasing.' +
                      index +
                      '.fees_list.' +
                      k +
                      '.contract_rentInfo.' +
                      j +
                      '.pay_time'
                      " :rules="{
    required: true,
    message: '缴费时间不能为空',
    trigger: 'change',
  }" style="flex: 1">
                      <el-date-picker v-model="pay.pay_time" class="public-input" @change="
                        listStartChange(
                          $event,
                          pay,
                          j,
                          list.contract_rentInfo
                        )
                        " :clearable="false" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        placeholder="请选择缴费时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="实际缴费时间:" style="flex: 1">
                      <el-date-picker v-model="pay.reality_pay_time" class="public-input" :clearable="false"
                        format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择实际缴费时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="已缴费金额:" style="flex: 1">
                      <el-input placeholder="请输入缴费金额" @change="alreadyPay(pay)" v-model="pay.old_pay"
                        class="public-input"></el-input>
                    </el-form-item>
                  </div>
                  <div style="
                      margin-bottom: 10px;
                      display: flex;
                      align-items: center;
                      flex-wrap: wrap;
                    ">
                    <el-form-item label="减免:" style="flex: 1">
                      <el-checkbox v-model="pay.is_derate">是否减免</el-checkbox>
                    </el-form-item>
                    <el-form-item label="减免开始时间:" v-if="pay.is_derate" style="flex: 1">
                      <el-date-picker v-model="pay.derate_start_time" :pickerOptions="{
                        disabledDate(time) {
                          return (
                            time.getTime() <
                            new Date(
                              getListStartTime(index, k, list, j)
                            ).getTime() -
                            24 * 60 * 60 * 1000 ||
                            time.getTime() >
                            new Date(pay.pay_end_time).getTime()
                          );
                        },
                      }" class="public-input" :clearable="false" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        placeholder="请选择减免开始时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="减免结束时间:" v-if="pay.is_derate" style="flex: 1">
                      <el-date-picker v-model="pay.derate_end_time" class="public-input" :pickerOptions="{
                        disabledDate(time) {
                          return (
                            time.getTime() <
                            new Date(
                              getListStartTime(index, k, list, j)
                            ).getTime() -
                            24 * 60 * 60 * 1000 ||
                            time.getTime() >
                            new Date(pay.pay_end_time).getTime()
                          );
                        },
                      }" :clearable="false" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        placeholder="请选择减免结束时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="减免金额:" v-if="pay.is_derate" style="flex: 1">
                      <el-input placeholder="请输入缴费金额" v-model="pay.derate_pay" class="public-input"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-form-item v-if="item.show">
                  <span style="color: red">多次缴费金额之和等于应缴总金额</span>
                </el-form-item>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
      </div>
      <el-row>
        <el-col :span="5" v-for="(item, index) in form.monthTotal" :key="index">
          <el-form-item :label="`租期内总${item.contract_fee_name}`">
            <el-input-number :placeholder="`租期内总${item.contract_fee_name}`" :controls="false" :min="0" type="Number"
              @change="calculateTotalData($event, index)" v-model="item.total_pay_price" class="public-input">
            </el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="总金额:">
            <el-input placeholder="总金额" v-model="form.total_price" :disabled="true" class="public-input"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { formatTimeHms } from "../../../../plugin/common";

export default {
  name: "paymentAmount",
  data () {
    return {
      active_name: "",
    };
  },
  computed: {
    ...mapState("contract", ["form", "rules"]),
  },
  methods: {
    ...mapMutations("contract", [
      "calculateMonthlyAmount",
      "calculateIncrease",
      "calculateRentInfo",
      "initProgressiveIncrease",
      "setIscomplete",
    ]),
    formatTimeHms,
    disEndTime (index, k, list, j) {
      let time = "";
      if (index === 0) {
        if (j === 0) {
          time = new Date(this.form.contract_start).getTime();
        } else {
          time = new Date(list.contract_rentInfo[j - 1].pay_end_time).getTime();
        }
      } else {
        if (j === 0) {
          time = new Date(this.getListStartTime(index, k, list, j)).getTime();
        } else {
          time = new Date(list.contract_rentInfo[j - 1].pay_end_time).getTime();
        }
      }
      return time;
    },
    getListStartTime (index, k, list, j) {
      let time = "";
      if (index === 0) {
        if (j === 0) {
          time = this.form.contract_start;
        } else {
          time = list.contract_rentInfo[j - 1].pay_end_time
            ? formatTimeHms(
              new Date(list.contract_rentInfo[j - 1].pay_end_time).getTime() +
              24 * 60 * 60 * 1000
            )
            : "请选择上一周期结束时间";
        }
      } else {
        if (j === 0) {
          time = formatTimeHms(
            new Date(
              this.form.contract_increasing[index - 1].fees_list[
                k
              ].contract_rentInfo[
                this.form.contract_increasing[index - 1].fees_list[k]
                  .contract_rentInfo.length - 1
              ].pay_end_time
            ).getTime() +
            24 * 60 * 60 * 1000
          );
        } else {
          time = list.contract_rentInfo[j - 1].pay_end_time
            ? formatTimeHms(
              new Date(list.contract_rentInfo[j - 1].pay_end_time).getTime() +
              24 * 60 * 60 * 1000
            )
            : "请选择上一周期结束时间";
        }
      }
      return time;
    },
    listStartChange (e, pay, j, pData) {
      if (j != 0) {
        if (new Date(pData[j - 1].pay_time).getTime() > new Date(e).getTime()) {
          this.$message({
            message: "警告哦，不能小于上一次缴费时间！",
            type: "warning",
          });
          pay.pay_time = "";
        }
      }
      for (let i = j + 1; i <= pData.length - 1; i++) {
        pData[i].pay_time = "";
      }
      console.log(e, pay);
    },
    listEndChange (e, pay, j, pData) {
      if (j != 0) {
        if (
          new Date(pData[j - 1].pay_end_time).getTime() >= new Date(e).getTime()
        ) {
          this.$message({
            message: "警告哦，不能小于上一次结束时间！",
            type: "warning",
          });
          pay.pay_end_time = "";
        }
      }
      for (let i = j + 1; i < pData.length - 1; i++) {
        pData[i].pay_end_time = "";
      }
      console.log(e, pay);
    },
    blurRentInfo (item, list, k) {
      console.log("缴费周期", item.fees_list[k].contract_rent_total);
      if (item.fees_list[k].contract_rent_total * 1 <= 0) {
        item.fees_list[k].contract_rent_total = 1;
      }
      let dataOneS = list.contract_rentInfo[0].pay_time;
      let dataOnee =
        list.contract_rentInfo[list.contract_rentInfo.length - 1].pay_end_time;
      list.contract_rentInfo = [];
      this.$set(item, "show", false);
      this.setIscomplete(false);
      if (!list.contract_rent_total) {
        return;
      }
      let tempArr = [];
      let remainder = list.total % parseInt(list.contract_rent_total);
      let average = Number(
        list.total / parseInt(list.contract_rent_total)
      ).toFixed(2);

      for (let i = 0; i < parseInt(list.contract_rent_total); i++) {
        let time = new Date().getTime() + 1 * 60 * 60 * 100 * i;
        if (i != parseInt(list.contract_rent_total) - 1) {
          let pay_time = "";
          if (i == 0) {
            pay_time = dataOneS;
          }
          tempArr.push({
            bill_status: 0,
            contract_fee_name: "基础费用",
            is_derate: false,
            old_pay: "0",
            pay_price: Number(average),
            pay_time: pay_time,
            pay_end_time: "",
          });
        } else {
          tempArr.push({
            bill_status: 0,
            contract_fee_name: "基础费用",
            is_derate: false,
            old_pay: "0",
            pay_price: Number(average),
            pay_time: i == 0 ? dataOneS : "",
            pay_end_time: dataOnee,
          });
        }
      }
      this.$set(list, "contract_rentInfo", tempArr);
    },
    amountPayment (event, item, parent, itemParent) {
      item.pay_price = Number(item.pay_price);
      if (event == "") {
        item.pay_price = 0;
      }
      if (Number(event) < Number(item.old_pay)) {
        item.pay_price = item.old_pay;
        this.$common.notifyError("缴费金额不能低于已缴金额");
        return;
      }
      let total = 0;
      parent.contract_rentInfo.forEach((pay) => {
        total += Number(pay.pay_price);
      });
      if (total != Number(parent.total)) {
        this.$set(itemParent, "show", true);
        this.setIscomplete(true);
      } else {
        this.$set(itemParent, "show", false);
        this.setIscomplete(false);
      }
    },
    setContractRentTotal (event, list) {
      this.$set(list, "contract_rent_total", event);
      // list.contract_rent_totals = event;
      // debugger;
    },
    alreadyPay (item) {
      // item.pay_price = '';
      this.setIscomplete(false);
    },
    calculateData () {
      this.calculateIncrease();
    },
    calculateMonth (event, index) {
      let val = parseFloat(event);
      let average = parseFloat(
        val / this.form.contract_fee_setting.length
      ).toFixed(2);
      let total = 0;
      this.form.contract_fee_setting.forEach((item) => {
        item[parseInt(index) + 1].pay_price = parseFloat(average);
        total += parseFloat(average);
      });
      this.form.contract_fee_setting[this.form.contract_fee_setting.length - 1][
        parseInt(index) + 1
      ].pay_price = Number(
        (
          parseFloat(average) + Math.abs((total - parseFloat(val)).toFixed(2))
        ).toFixed(2)
      );
      this.calculateMonthlyAmount();
      let time = this.$common.monthsBetw(
        this.form.contract_start,
        this.form.contract_end
      );
      let years = Math.ceil(time.months / 12);
      this.initProgressiveIncrease(years);
      this.calculateIncrease();
      this.setIscomplete(false);
    },
    calculateTotalData (event, index) {
      let val = parseFloat(event);
      let time = this.$common.monthsBetw(
        this.form.contract_start,
        this.form.contract_end
      );
      let average =
        (val / time.months).toFixed(2) / this.form.contract_fee_setting.length;
      let total = 0;
      this.form.contract_fee_setting.forEach((item) => {
        item[parseInt(index) + 1].pay_price = average;
        total += average;
      });
      total *= time.months;
      this.form.contract_fee_setting[this.form.contract_fee_setting.length - 1][
        parseInt(index) + 1
      ].pay_price = Number(
        parseFloat(
          average + Math.abs((total - parseFloat(val)).toFixed(2))
        ).toFixed(2)
      );
      this.calculateMonthlyAmount();
      let years = Math.ceil(time.months / 12);
      this.initProgressiveIncrease(years);
      this.calculateIncrease();
      this.setIscomplete(false);
    },
    isdyZero (rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入缴费金额"));
      }
      if (value <= 0) {
        return callback(new Error("缴费金额不能小于等于0"));
      }
      return callback();
    },
    setDisabled (item, index, parentIndex) {
      if (this.forbid) {
        return true;
      }
      if (index == 0 && parentIndex == 0) {
        return false;
      }
      if (index > 0 && !item[index - 1].pay_time) {
        return true;
      }
      if (index == 0 && parentIndex >= 0) {
        let parentData = this.form.contract_increasing[parentIndex - 1];
        if (parentData.contract_rentInfo.length == 0) {
          return true;
        } else {
          let len = parentData.contract_rentInfo.length - 1;
          if (parentData.contract_rentInfo[len].pay_time) {
            return false;
          } else {
            return true;
          }
        }
      }
      return false;
    },
    pickerOptionsStart (item, index, parentIndex) {
      if (this.form.contract_increasing.length > 0) {
        return {
          disabledDate: (time) => {
            if (index == 0 && parentIndex == 0 && this.form.contract_start) {
              let endTime = new Date(this.form.contract_start).getTime();
              return time.getTime() < endTime;
            } else if (index == 0) {
              let len =
                this.form.contract_increasing[parentIndex - 1].contract_rentInfo
                  .length - 1;
              let endTime = new Date(
                this.form.contract_increasing[
                  parentIndex - 1
                ].contract_rentInfo[len].pay_time
              ).getTime();
              return time.getTime() < endTime;
            } else {
              let endTime = new Date(item[index - 1].pay_time).getTime();
              return time.getTime() < endTime;
            }
          },
        };
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.labelwidth {
  width: 400px !important;
}

.public-tips {
  margin-left: 10px;
}

/deep/ .el-input-number .el-input__inner {
  text-align: left;
}
</style>
